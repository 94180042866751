import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../layout/layout'

import { Hero, GetStarted, WhatWeDo, FeaturedQuote, SectionServices, Gallery } from '../components'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const quote = get(this, 'props.data.contentfulTestimonial')
    const gallery = get(this, 'props.data.allContentfulGallery.edges')
    const heroImage = get(this, 'props.data.file.childImageSharp.fluid')

    return (
      <Layout location={this.props.location}>
        <Helmet title={`${siteTitle} | Take control of your home`} />
        <Hero
          title='Take control of your home'
          subtitle='With our award winning Control4 systems.'
          image={heroImage}
          button='Get in touch'
          buttonUrl='/contact'
        />
        <WhatWeDo />
        <GetStarted />
        <SectionServices />
        <FeaturedQuote data={quote} />
        <Gallery title='Some of our latest work' data={gallery} />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "hero-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1180, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulGallery(filter: { isFeatured: { eq: "Yes" } }, limit: 10) {
      edges {
        node {
          id
          assets {
            title
            fluid(maxWidth: 1000, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    contentfulTestimonial(featuredQuote: { eq: "Yes" }) {
      name
      jobTitle
      quote {
        quote
      }
      image {
        fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
